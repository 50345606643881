@import 'abstracts/_abstracts-dir';

@font-face {
  font-family: SukhumvitBold;
  src: url('./fonts/SukhumvitSet-Bold.ttf');
  display: swap;
}

@font-face {
  font-family: SukhumvitRegular;
  src: url('./fonts/SukhumvitSet-Text.ttf');
  display: swap;
}

* {
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: SukhumvitRegular;
}

.fontBold {
  font-family: SukhumvitBold !important;
  letter-spacing: 0px;
}

.fontRegular {
  font-family: SukhumvitRegular !important;
  letter-spacing: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
q {
  margin: 0;
}

h1 {
  font-size: 48px;
  font-weight: 100;
  line-height: 50px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 39px;
  }
}

h2 {
  font-size: 39px;
  font-weight: 100;
  line-height: 50px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 32px;
  }
}

h3 {
  font-size: 31px;
  font-weight: 100;
  line-height: 40px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 27px;
  }
}

h4 {
  font-size: 25px;
  font-weight: 100;
  line-height: 40px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 22px;
  }
}

h5 {
  font-size: 20px;
  font-weight: 100;
  line-height: 30px;
  @extend .fontBold;

  @media (max-width: map-get($breakpoints, md)) {
    font-size: 18px;
  }
}

h6 {
  font-size: 16px;
  font-weight: 100;
  line-height: 30px;
  @extend .fontBold;
}

//Paragraph Bold
label {
  font-size: 14px;
  font-weight: 100;
  line-height: 25px;
  @extend .fontBold;
}

//Paragraph
p {
  font-size: 14px;
  font-weight: 100;
  line-height: 25px;
  @extend .fontRegular;
}

//Body (Bold)
span {
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  @extend .fontBold;
}

//Body
q {
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  @extend .fontRegular;

  &::before {
    content: '';
  }

  &::after {
    content: '';
  }
}

//Table
.tableMain {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  th {
    background-color: map-get($primary, primary1);
    color: map-get($blackWhite, white);
    padding: 15px;
    font-size: 16px;
    font-weight: 100;
  }
  td {
    padding: 15px;
    text-align: center;
    font-size: 14px;
  }
  tr:hover {
    background-color: map-get($blackWhite, gray3);
    cursor: pointer;
  }
}

.errorMsg {
  color: #fd754a;
  font-size: 14px;
  width: 100%;
}


// Loading
.loader {
  width: 32px;
  height: 90px;
  display: block;
  margin: 20px auto;
  position: relative;
  border-radius: 50% 50% 0 0;
  border-bottom: 10px solid #FF3D00;
  background-color: #FFF;
  background-image: radial-gradient(ellipse at center, #FFF 34%, #FF3D00 35%, #FF3D00 54%, #FFF 55%), linear-gradient(#FF3D00 10px, transparent 0);
  background-size: 28px 28px;
  background-position: center 20px , center 2px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: animloaderBack 1s linear infinite alternate;
}
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 0px 15px #FF3D00 inset;
  top: 67px;
}
.loader::after {
  content: '';  
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 34px;
  height: 34px;
  top: 112%;
  background: radial-gradient(ellipse at center, #ffdf00 8%, rgba(249, 62, 0, 0.6) 24%, rgba(0, 0, 0, 0) 100%);
  border-radius: 50% 50% 0;
  background-repeat: no-repeat;
  background-position: -44px -44px;
  background-size: 100px 100px;
  box-shadow: 4px 4px 12px 0px rgba(255, 61, 0, 0.5);
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloaderBack {
  0%, 30%, 70% {
    transform: translateY(0px);
  }
  20%, 40%, 100% {
    transform: translateY(-5px);
  }
}

@keyframes animloader {
  0% {
    box-shadow: 4px 4px 12px 2px rgba(255, 61, 0, 0.75);
    width: 34px;
    height: 34px;
    background-position: -44px -44px;
    background-size: 100px 100px;
  }
  100% {
    box-shadow: 2px 2px 8px 0px rgba(255, 61, 0, 0.5);
    width: 30px;
    height: 28px;
    background-position: -36px -36px;
    background-size: 80px 80px;
  }
}